<template>
  <div class="child-page-div">
    <div class="page-content-div">
      <validation-observer ref="form">
        <div class="items-div" v-for="items in priceItems">
          <div v-for="item in items">
            <validation-provider rules="required|amount" v-slot="{ errors }" name="price">
              <div class="item-div">
                <div class="item-title-div">{{ item.name }}</div>
                <div>
                  <label><input type="number" v-model="item.price" placeholder="请输入价格">元</label>
                </div>
              </div>
              <div class="err-tips-div err-tips">{{ errors[0] }}</div>
            </validation-provider>
          </div>
        </div>
      </validation-observer>
      <div class="btn-div">
        <input type="button" value="保存" @click="onSave">
      </div>
    </div>
  </div>
</template>

<script>
import { getPriceItems, postPriceEdit } from "@/common/api";

export default {
  name: "PaymentPrice",
  data() {
    return {
      priceItems: [
        [{type: 61, name: '用户周会员', price: 0}, {type: 62, name: '用户月会员', price: 0}],
        [{type: 63, name: '用户季会员', price: 0}, {type: 1, name: '用户年会员', price: 0}],

        [{type: 26, name: '修理工周会员', price: 0}, {type: 27, name: '修理工月会员', price: 0}],
        [{type: 28, name: '修理工季会员', price: 0}, {type: 25, name: '修理工年会员', price: 0}],

        [{type: 30, name: '驾驶员周会员', price: 0}, {type: 31, name: '驾驶员月会员', price: 0}],
        [{type: 32, name: '驾驶员季会员', price: 0}, {type: 29, name: '驾驶员年会员', price: 0}],

        [{type: 34, name: '拖车周会员', price: 0}, {type: 35, name: '拖车月会员', price: 0}],
        [{type: 36, name: '拖车季会员', price: 0}, {type: 33, name: '拖车年会员', price: 0}],

        [{type: 38, name: '车床加工周会员', price: 0}, {type: 39, name: '车床加工月会员', price: 0}],
        [{type: 40, name: '车床加工季会员', price: 0}, {type: 37, name: '车床加工年会员', price: 0}],

        [{type: 42, name: '电焊周会员', price: 0}, {type: 43, name: '电焊月会员', price: 0}],
        [{type: 44, name: '电焊季会员', price: 0}, {type: 41, name: '电焊年会员', price: 0}],

        [{type: 46, name: '压油管周会员', price: 0}, {type: 47, name: '压油管月会员', price: 0}],
        [{type: 48, name: '压油管季会员', price: 0}, {type: 45, name: '压油管年会员', price: 0}],

        [{type: 50, name: '五金店周会员', price: 0}, {type: 55, name: '五金店月会员', price: 0}],
        [{type: 56, name: '五金店季会员', price: 0}, {type: 49, name: '五金店年会员', price: 0}],

        [{type: 58, name: '销售员周会员', price: 0}, {type: 59, name: '销售员月会员', price: 0}],
        [{type: 60, name: '销售员季会员', price: 0}, {type: 57, name: '销售员年会员', price: 0}],

        [{type: 67, name: '物流周会员', price: 0}, {type: 68, name: '物流月会员', price: 0}],
        [{type: 69, name: '物流季会员', price: 0}, {type: 66, name: '物流年会员', price: 0}],

        [{type: 75, name: '叉车周会员', price: 0}, {type: 76, name: '叉车月会员', price: 0}],
        [{type: 77, name: '叉车季会员', price: 0}, {type: 70, name: '叉车年会员', price: 0}],

        [{type: 79, name: '空压机周会员', price: 0}, {type: 80, name: '空压机月会员', price: 0}],
        [{type: 85, name: '空压机季会员', price: 0}, {type: 78, name: '空压机年会员', price: 0}],

        [{type: 71, name: '配件店周会员', price: 0}, {type: 72, name: '配件店月会员', price: 0}],
        [{type: 73, name: '配件店季会员', price: 0}, {type: 2, name: '配件店年会员', price: 0}],

        [{type: 81, name: '生产厂家周会员', price: 0}, {type: 82, name: '生产厂家月会员', price: 0}],
        [{type: 83, name: '生产厂家季会员', price: 0}, {type: 3, name: '生产厂家年会员', price: 0}],

        [{type: 91, name: '代理商周会员', price: 0}, {type: 92, name: '代理商月会员', price: 0}],
        [{type: 93, name: '代理商季会员', price: 0}, {type: 4, name: '代理商年会员', price: 0}],

        [{type: 11, name: '商家信息置顶', price: 0}, {type: 12, name: '服务信息置顶', price: 0}],
        [{type: 13, name: '二手整机置顶', price: 0}, {type: 14, name: '二手配件置顶', price: 0}],
        [{type: 16, name: '疑难配件置顶', price: 0}, {type: 15, name: '招聘置顶', price: 0}],
        [{type: 17, name: '租赁置顶', price: 0}, {type: 18, name: '代理置顶', price: 0}],

        [{type: 64, name: '维修教学年会员', price: 0}, {type: 65, name: '维修教学终生会员', price: 0}],
        [{type: 88, name: '申请办理操作证', price: 0}, {type: 89, name: '到期更换操作证', price: 0}]
      ]
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      getPriceItems()
        .then(data => {
          if(Array.isArray(data)){
            data.forEach(item => {
              this.priceItems.some(v => {
                return v.some(value => {
                  if(value.type === item.type) {
                    value.price = item.price / 100
                    return true
                  }else{
                    return false
                  }
                })
              })
            })
          }
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询价格失败，请稍后重试");
          }
        })
    },
    onSave() {
      this.$refs.form.validate().then(res => {
        if(res){
          const formData = new FormData();
          let index = 0;
          this.priceItems.forEach(item => {
            item.forEach(v => {
              formData.append(`prices[${index}].type`, v.type);
              formData.append(`prices[${index}].price`, `${v.price * 100}`);
              ++index;
            })
          })

          postPriceEdit(formData)
            .then(() => {
              this.$layer.msg("操作成功");
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.$layer.msg(error.data.msg);
              } else {
                this.$layer.msg("操作失败");
              }
              this.initData();
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.child-page-div {
  width: 100%;
  .page-content-div {
    width: 760px;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 20px;
    .items-div {
      display: flex;
      justify-content: space-between;
      .item-div {
        display: flex;
        align-items: center;
        .item-title-div {
          width: 120px;
          margin-right: 5px;
          text-align: right;
        }
        input {
          width: 200px;
          height: 25px;
          padding: 0 5px;
          margin-right: 5px;
        }
      }
      .err-tips-div {
        width: 100%;
        height: 25px;
        padding-left: 125px;
        box-sizing: border-box;
        font-size: 12px;
      }
    }
    .btn-div {
      width: 100%;
      text-align: center;
      input {
        width: 80px;
        height: 30px;
      }
    }
  }
}
</style>